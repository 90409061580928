body {
  max-width: max-content;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

#root {
  max-width: 800px;
}

.container {
  padding: 50px;
}

.logo {
  display: block;
  margin: 0 auto;
}

hr {
  border: 1px solid #dbdbdb;
  margin: 10px 0;
}

input {
  font-size: 1.3rem;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #dbdbdb;
  box-shadow: 4px 4px 10px rgba(88, 88, 88, 0.06);
}

.message {
  border: 2px solid black;
  padding: 10px;
  border-radius: 15px;
  color: black;
  font-size: 1.5rem;
}

.success {
  border: 2px solid green;
  color: green;
}

.message:empty {
  border: none;
}
